import { useEffect, useState, useRef } from "react";
import {Sun, Moon, Iowa, Check, Close} from './icons'
import {LeftArrow, RightArrow, Hello, SirSmiles, Soup, ErrorBubble, CoolExperiment, LongTime, LessBoxes} from './drawings'


const TECHNOLOGIES = [
  ['React', 'https://reactjs.org', '(with and without hooks)'],
  ['Material UI', 'https://mui.com', ''],
  ['SWR', 'https://swr.vercel.app', ''],
  ['Fetch', 'https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API', ''],
  ['Axios', 'https://axios-http.com', ''],
  ['Redux', 'hhttps://redux.js.org', ''],
  ['Node', 'https://node.org', ''],
  ['Hapi', 'https://hapi.dev', ''],

  ['Network Programming', '', 'Clients and servers all the way down'],
  ['HTTP service orchestration','', '...including retries, headers, token-based authentication'],
  ['Automated Testing'],
  ['Python','https://www.python.org', '(2 and 3)'],
  ['Flask', 'https://palletsprojects.com/p/flask/'],
  ['Redis','https://redis.io','(GCP Hosted)'],
  ['Auth0','https://auth0.com', 'with our own layer of roles and permissions'],

  ['GCP\'s Datastore','https://cloud.google.com/datastore','(Legacy)'],
  ['GCP Pub/Sub','https://cloud.google.com/pubsub'],
  ['GCP Cloud Functions', 'https://cloud.google.com/functions'],
  ['Google Cloud Run','https://cloud.google.com/run'],
  ['Google Cloud Storage','https://cloud.google.com/storage'],
  ['Google Kubernetes Engine (GKE)','https://cloud.google.com/kubernetes-engine'],
  ['Datadog','https://datadog.com','..for RUM, Traces and Flame graphs'],

  ['Jetbrains IDEs', 'https://www.jetbrains.com'],
  ['VS Code', 'https://code.visualstudio.com'],

  ['Slack', 'https://slack.com'],
  ['Google Workspace','https://workspace.google.com'],
  ['Github', 'https://github.com'],
  ['Jira', 'https://www.atlassian.com/software/jira'],
  ['Miro', 'https://miro.com'],
  ['Zoom', 'https://www.zoom.com'],
  ['Google Meet', 'https://meet.google.com'],
]

const hiringManagerName = "Nick Tuck";
const hiringManagerEmail = "nick.tuck@gaincompliance.com"

const LightDarkToggle = () => {
  const [dark, setDark] = useState(true);

  useEffect(() => {
    document.documentElement.className = dark ? 'dark' : '';
  }, [dark]);

  useEffect(() => {
    // sync with system now
    const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    setDark(isDark);

    // watch if the system changes
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const isDark = !!e.matches
      setDark(isDark);
    });
  },[])

  return (
    <div className="mx-1 flex items-center text-blue-700 dark:text-blue-600 select-none">
      <Moon />
      <div className="p-1 w-10 mx-1 border border-blue-700 rounded-full" onClick={() => {
        measureEvent('theme-' + (dark ? 'dark' : 'light'));
        setDark(!dark);
      }}>
        <div className="h-4 w-4 bg-blue-700 rounded-full"
        style={{
          marginLeft: dark ? "" : "0.9rem",
          transition: "margin-left 0.25s"
        }}
        />
      </div>
      <Sun />
    </div>
  )
}

const Image = ({src, description}) => {
  if(!description){
    description = src.replace(/[^a-zA-Z0-9]/,' ');
  }

  return (
    <div className="-mx-6 lg:-mx-32 my-8 py-4 px-2 rounded bg-gray-100 dark:bg-gray-800">
      <img className="object-contain mx-auto"
          src={src}
          alt={description} />

      <p className="text-gray-500 mt-0 lg:-mt-4 mx-10 lg:mx-24 text-center">{description}</p>
    </div>
    )
}

const BioImage = ({src, title, tenure, linkedInUrl}) => (
    <div className="my-2 pt-4 pb-2 px-5 rounded bg-gray-100 dark:bg-gray-800 max-w-xs mx-auto">
      <img className="object-contain mx-auto"
           src={src}
           alt={title}/>
      <div className="mt-2 flex items-center">
        <a href={linkedInUrl} target="_blank" rel="noopener noreferrer"><img src="bios/linkedin.png" alt="linkedin logo" className="h-7 float-left "/></a>
        <span className="text-gray-400 text-2xl ml-1">{title}</span>
      </div>
      <div className="text-gray-500 text-sm ml-8">tenure: {tenure}</div>
    </div>
)

const DismissableNoticeOverlay = ({buttonText, children}) => {
  const [dismissed, setDismissed] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {setShow(true)}, 1000)
    return () => clearTimeout(timer);
  }, []);

  if(dismissed) return null;

  return (
    <div className={`z-50 lg:top-1/3 top-1/4 lg:w-3/4 sm:ml-auto sm:mr-auto moving-gradients px-16 py-10 pb-8 rounded-xl text-2xl lg:text-3xl font-hand fixed -m-12 lg:xm-auto text-white shadow-2xl border-4 border-white ${show ? 'opacity-100': 'opacity-0 rotate-45'}}`} 
      style={{maxWidth:"800px", transform: `rotate(${Math.random()*10 - 5}deg)`, transition: "opacity 1s, transform 0.5s"}}
    >
      <div>
        {children}
      </div>
      <button onClick={() => setDismissed(true)} className="rounded-full bg-white text-orange-500 float-right py-2 px-4 mt-6 text-xl hover:bg-pink-700 hover:text-white">{buttonText}</button>
    </div>
  )
}

const QuoteImages = ({ name, images }) => {
  const firstAngle = Math.random() * 10 - 5;
  const angles = [firstAngle, (firstAngle + 5) % 10, (firstAngle - 5) % 10];

  return (
    <div className="mt-4 p-4 pb-2 -mx-6 lg:-mx-32">
      <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-6 ">
        {images.map((src, index) => (
            <img key={src} src={src} alt={`a quote of ${name}`} className="h-72 shadow-xl border-white border-8" style={{transform: `rotate(${angles[index]}deg)`}} />
        ))}
      </div>
      <p className="text-gray-500 dark:text-blue-300 mt-8 text-center italic text-sm">Quotes {name} has said in a meeting or in slack.</p>
    </div>)
}

const TechLink = ({href, children, description}) => {
  const Wrapper = !!href ? ({children, href}) => <a target="__blank" className="underline" href={href}>{children}</a> :
      ({children}) => <>{children}</>

  return (
      <span
          className=" text-blue-900 dark:text-blue-200 inline-flex items-center text-xl font-mono mr-3 mb-3 bg-blue-100 dark:bg-blue-900 px-2 py-1 rounded"
      style={{transform: `rotate(${Math.random() * 2 - 1}deg)`}}
      title={description}
    >
      <Wrapper href={href}>{children}</Wrapper>
    </span>
  )

}

const H1 = ({children}) => (<h1 className="mt-12 lg:mt-20 mb-6 text-5xl text-blue-700 dark:text-yellow-300 font-hand text-center" >{children}</h1>)
const H2 = ({children}) => (<h2 className="mt-8 lg:mt-12 mb-6 text-4xl text-blue-700 dark:text-yellow-600 font-hand text-center" >{children}</h2>)
const H3 = ({children}) => (<h3 className="text-xl font-bold" >{children}</h3>)

const P = ({children, size="text-xl", className}) => (<p className={`mt-4 ${size} leading-relaxed ${className}`}>{children}</p>)
const Strong = ({children}) => (<span className="font-bold">{children}</span>)
const Strike = ({children}) => (<span className="line-through opacity-50">{children}</span>)
const Sneak = ({children}) => (<span className="font-italic bg-grey-500">{children}</span>)
const A = ({children, href, target ="_top"}) => (<a className="underline decoration-2 underline-offset-2 font-hand" href={href} target={target}>{children}</a>)

const Section = ({children}) => (
  <section className="py-4 px-6 dark:text-blue-100 lg:max-w-3xl lg:mx-auto">
    {children}
  </section>
)

const Grid = ({children}) => (<div className="md:grid md:grid-cols-2 lg:-mx-40 lg:grid-cols-3 gap-6">{children}</div>)

const GridBox = ({children, graphic}) => (
  <div className="mt-4 md:mt-0 p-4 rounded bg-blue-100 dark:bg-blue-800 shadow">
    {/* <div className="w-20 h-12 m-auto mb-4 object-contain text-blue-800 border border-black">{graphic}</div> */}
    {children}
  </div>
)

const BioTitle = ({title, linkedInUrl}) => (
    <P className="flex items-center">
      <img src={'linkedin.png'} alt="linkedin logo" className="h-7 mr-2 bg-white" />{title}
    </P>
)

const SalaryWidget = ({midpoint}) => (
  <section>
    <div className="flex justify-around items-center my-8">
      <LeftArrow />
      <div>
        <p className="text-4xl lg:text-6xl text-center font-hand">{midpoint}</p>
      </div>
      <RightArrow />
    </div>
  </section>
)

const measureButtonPress = (buttonTitle) => {
  window.gtag('event', 'button', {'value': buttonTitle});
}


const measureEvent = (eventName) => {
  window.gtag('event', eventName);
}

const ResponseButton = ({label, onClick=() => {}}) => {
  const [pressed, setPressed] = useState(false);

  return (
    <button
      onClick={() => {
        if(!pressed){
          measureButtonPress(label);
        }
        setPressed(!pressed);
        onClick();
      }}
      className="flex items-center mt-4 mx-auto text-white text-xl lg:text-3xl rounded-lg px-4 py-2 border-2 border-white hover:bg-indigo-500 shadow-xl">
      {pressed && <div className="mr-5"><Check size={12}/></div>} {label}
    </button>
  )
}

const ContactForm = ({onSubmit = () => {}}) => {
  const Label = ({children}) => (<label className="block text-base md:text-lg mt-4 text-gray-500 dark:text-purple-500">{children}</label>);

  const inputStyle = "text-black dark:text-gray-100 dark:bg-gray-900 block p-1 md:p-4 md:mb-8 shadow-inner border border-blue-300 dark:border-black rounded text-base md:text-xl w-full"


  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");

  const save = () => {
    setIsSaving(true);
    window.fetch('/contact', {
      method:'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name, email, info
      })
    }).then((response) => {
      if (!response.ok) {
        alert('Aww beans we are having a tough time saving your data, just email me.');
      } else {
        onSubmit();
      }

    }).catch(() => {
      alert('Aww beans we are having a tough time saving your data, just email me.');
    })

  }

  return (
    <form>
      <Label>Your name </Label>
      <input className={inputStyle} type="text" value={name} onChange={(e) => setName(e.target.value)}  />

      <Label>Email </Label>
      <input className={inputStyle} type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>

      <Label>...anything else you'd like to share?</Label>
      <textarea className={inputStyle + " text-lg"} value={info} onChange={(e) => setInfo(e.target.value)}/>

      <button
        className="flex align-middle mt-8 text-xl font-hand  bg-blue-700 dark:bg-purple-600 rounded p-4 text-white"
        disabled={isSaving}
        onClick = {(e) => {
          e.stopPropagation();
          e.preventDefault();
          save();
        }}
      >
        {isSaving && <Check />}
        Let's do this
      </button>
    </form>
  )
}

const ContactModal = ({onHide = () => {}}) => {
  const cover = useRef(null);
  const [successfulSave, setSuccesfulSave] = useState(false);

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-75 "
      onClick={(e) => {
        // close if the background is clicked
        // disabled - too easy to close
        // if (e.target === cover.current){
        //   measureEvent('contact form abandon');
        //   onHide();
        // }
      }}
    >
      <div ref={cover} className="z-20 flex flex-col justify-center w-full h-screen">
        <div className="mt-12 lg:rotate-1 relative z-30 w-full lg:w-3/5 max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-xl py-4 lg:pt-8 lg:pb-24 drop-shadow-2xl border lg:border-none">
          <button
            className="absolute right-5 dark:text-gray-400 w-6 h-6"
            onClick={() => {
              measureEvent('contact form abandon');
              onHide();
            }}
          >
            <Close size={6}/>
          </button>
          {!successfulSave && <>
            <H1><span className="hidden md:inline">🥳</span> Great, let's talk! <span className="hidden md:inline">🎈</span></H1>

            <div className="mx-4 lg:mx-24 dark:text-purple-500">
              <ContactForm onSubmit={() => {
                measureEvent('form submitted');
                setSuccesfulSave(true);
              }} />
              <P>... or just send me an email <A href={`mailto:${hiringManagerEmail}`}>{hiringManagerEmail}</A> with a brief introduction.</P>
            </div>
          </>}

          {successfulSave && <>
            <H1>Amazing, thank you!</H1>
          </>}
        </div>
      </div>
    </div>
  )
}

function App() {
  const [showContact, setShowContact] = useState(false);

  return (
    <div className="gradient">
      <header className="pt-1 flex flex-row-reverse">
        <LightDarkToggle />
      </header>
      <Section>
        <div className="text-blue-700 dark:text-yellow-300 w-48 lg:w-64 mx-auto">
          <div className="drawn-animation">
            <Hello />
          </div>
        </div>

        <P>We are looking for a US based <Strong>product-minded experienced software engineer.</Strong> We have a successful product, with a growing customer base. The last few years we've been focused on simplifying the code and learning how to work together so much better. And now, we are at that turning point, where our code is becoming less of a bottleneck and our focus is on <Strong>fast moving ambitious product teams, eager to build high value solutions, through agile and iterative approaches, while still simplifying our codebase.</Strong></P>

        <DismissableNoticeOverlay buttonText="Ah beans, okay">
          <strong>Thanks to an incredible response, we've filled all of our open positions.</strong> 
          <p className="mt-6 text-lg lg:text-2xl opacity-80">
            You are welcome to read on, and submit the form below, but we won't be hiring again until the summer of 2025.
          </p>
        </DismissableNoticeOverlay>


        <div className="dark:hidden lg:-mx-20 py-10 pb-10"
             style = {{
              backgroundImage: "url(squiggles-yellow.png)",
              backgroundSize: '100% 100%'
             }}
        >
          <P size="text-xl lg:text-2xl text-center text-amber-800">Senior level engineers who are curious, playful, highly collaborative and can manage their way through (sometimes too much) existing code should read on.</P>
        </div>


        <div className="hidden dark:block lg:-mx-20 py-10 pb-10"
             style = {{
              backgroundImage: "url(squiggles-dark.png)",
              backgroundSize: '100% 100%',
             }}
        >
          <P size="text-xl lg:text-2xl text-center text-amber-400">Senior level engineers who are curious, playful, highly collaborative and can manage their way through (sometimes too much) existing code should read on.</P>
        </div>


        <H1>The Product</H1>

        <P>We are building an application for the insurance industry⚕️. <Strong>Yep</Strong>, it's niche. If you were to smash Google Docs  and Google Sheets together, intertwined with tens of thousands of formulas and the ability to export standardized PDF reports - you'd roughly have our application. <Sneak>(We wrote <A href="/structured-reporting-white-paper.pdf" target="_blank">a short article</A> about this approach a few years back, for a slightly deeper dive into this, if you are interested)</Sneak></P>

        <P>The great news is, our competitors are slumbering and we are able to gobble up their picnic. But that came at a cost, our fast moving first years have been a boon for the business, but had left a mess in our code and infrastructure. We have been radically simplifying over the past few years. Our challenge is to continue riding that wave 🏄 of simplification and spend more effort on building our product muscles. Past our initial startup phase, and on the way to simplified code, we have the luxury to start making hard choices on what to build next, how to build it, and verify we're creating the customer and business value we intended. We're committed to efficient, iterative product development, delivering frequent value to our customers using modern practices.</P>

        <Image src="notes-with-mixed-input-form-and-right-hand-panel-2024.png" description="A sample screenshot of our application showing mixed data types with the information panel expanded."/>

        <P>Our stack is fairly modern, running on Google infrastructure <img className="object-contain w-6 h-6 inline" src="old-man-yells-at-gcp.png" alt="Old Man Yells at GPC" /> and written in a mix of
          Python, React and Node. We are recovering from an over-eager exploration into microservice architecture, and
          some front end code paths still rely on too much Redux middleware and pre hooks patterns. We have been steering 🛞 toward
          less services, and simpler, modern react patterns. <Strong>We still have plenty of opportunity to adopt new and
          useful patterns in our technology, but our goals are building customer value first with technology enabling that goal.</Strong></P>

        <Image src="validation-dashboard-2024.png"
               description="The validation dashboard showing remaining items to address before the document can be filed. Also showing the custom rule syntax."/>

        <H1>How We Work</H1>

        <P>You'll be working with a small team of 8-ish engineers with minimal overhead and bureaucracies, who tend to thrive on pairing sessions and are comfortable talking about failures and how we can improve. </P>

        <P>Our engineers are split into two feature teams. While both teams can work on anything in our system, one team tends to focus on the parts of our product the customer interacts with (The Guild <img className="object-contain w-6 h-6 inline" src="guild-logo-2024.png" alt="Guild Logo" />)
          and the other tends to focus on the services that power the product (Core Team <img className="object-contain w-6 h-6 inline" src="core-logo-2024.jpg" alt="Core Logo" />). <Strong>Both
            teams are full stack</Strong>, with The Guild tending to have more presentational work, and Core more computational and data model focused.</P>

        <P><Strong>Gain Compliance's engineering organization is completely remote.</Strong> The company was founded in Des Moines, Iowa <Iowa /> but has employees scattered across the United States 🇺🇸. We use Github, Slack, Google Suite, Jira and frequent Zoom pairing sessions - which means we're looking for a great multi-medium communicator who is comfortable listening, teaching, laughing, arguing, convincing and sharing their ideas in remote-friendly environments.</P>

        <P>We try our best to make planning, designing and building an interactive and collaborative exercise, <Strong>believing that our best work comes when we can all contribute ideas in a safe place.</Strong> To facilitate this we have shared product and business goals, as well as  time to imagine and explore solutions.  We have annual product goals roughly mapped to a quarterly roadmap, with work being broken up into two week sprints (though we use this word pretty loosely). We've borrowed some ideas from agile methodologies, but aren't dogmatic about it.</P>

        <P>Your first few months will be stumbling through understanding our business and existing patterns. This should be driven, if not accelerated, by your curiosity. <Strong>You'll be expected to challenge how we architect, plan, execute and deliver product-focused engineering</Strong> while working alongside our product manager and existing engineering team to build an awareness of the existing implementation while planning to improve it.</P>

        <H1>Our Technical Toolkit</H1>
        <P>We use the following tools, languages, frameworks, patterns and approaches to build our product. Hopefully these things interest you, and you've either had experience with them or you don't mind learning them.</P>
        <div className="mt-2">
          {TECHNOLOGIES.map((tech, i) => {
            const [name, link, description] = tech;
            return <TechLink key={i} href={link} description={description}>{name}</TechLink>
          })}
        </div>

      </Section>

      <section className="p-12 pt-4 mt-4 bg-amber-100 dark:bg-amber-600 dark:text-amber-900 lg:shadow-xl">
        <div className="max-w-5xl mx-auto">
          <H1>Salary & Compensation</H1>
          <P className="max-w-2xl mx-auto">This is the midpoint for this role. We don't want you to wait to the end of the interview cycle to see this number, let's just plop this right here.</P>
          <SalaryWidget midpoint="$165k"/>
          <P className="text-center max-w-2xl mx-auto">This will be adjusted per candidate based on experience, skills and incoming title. You will also be given stock options that vest over a 5 year period.</P>
        </div>
      </section>

      <Section>
        <H1>Challenges Ahead in 2025</H1>
        <Grid>
          <GridBox graphic={<CoolExperiment />}>
            <H3>Comfortable Experimenting</H3>
            <P className="text-lg">We have been embracing fast feedback and quick explorations. We want to lean in even further. We need opinionated and experienced individuals to accelerate this goal.</P>
          </GridBox>

          <GridBox graphic={<ErrorBubble />}>
            <H3>Riding the Data Tsunami</H3>
            <P className="text-lg">Our clients are getting bigger and their data is exploding. We need to keep scaling to keep up. Cloud computing is great, but we'll need to get smart with our UX for those really huge datasets.</P>
          </GridBox>

          <GridBox graphic={<Soup />}>
            <H3>Less is More</H3>
            <P className="text-lg">Simplification was once our top goal. As we recover, it's still crucial. We need sharp eyes improving our remaining redux soup and overly abstract backend, especially in less-active code paths.</P>
          </GridBox>

          <GridBox graphic={<LongTime />}>
            <H3>Managing long running tasks</H3>
            <P className="text-lg">Our app can export large PDF and zip files. Doing so can take up to a dozen minutes. We need to gain competence handling long running jobs that connect to multiple services running atop the elasticity of google's serverless infrastructure.</P>
          </GridBox>

          <GridBox graphic={<SirSmiles />}>
            <H3>Snappy Customer Experience</H3>
            <P className="text-lg">Get our web app as performant as a desktop experience. Too often our interactions can take seconds, I bet we can get this down to 100ms.</P>
          </GridBox>

          <GridBox graphic={<LessBoxes />}>
            <H3>Monitoring + Troubleshooting</H3>
            <P className="text-lg">More solutions means more things that can go wrong. We have a few ways we monitor behavior, metrics and logs. We're improving our ability to extract insights form that data. But we're gonna need to come up with even better patterns to efficiently troubleshoot and resolve issues.</P>
          </GridBox>

        </Grid>
        <P>In the spirit of openness, you can read our top three themes for our engineering team this year - <A href="/2025-engineering-themes.pdf" target="_blank">Engineering Themes 2025</A>.</P>


        <H1>Benefits</H1>
        <P>Gain Compliance is a late stage startup. We are focused on producing a great product while developing a sustainable culture. While we don't have all the perks of a large organization, we make up for this with incredible autonomy and the personal relationships of a small company. This means you will not be burdened with heavy processes, you can have a significant impact on both product and technology, and your opinions and ideas will be valued.</P>

        <P>We have full company-paid medical, vision and dental health benefits. We are happy to share our complete health benefits packet upon request.</P>

        <P>A generous vacation policy for all employees with no blackout dates.</P>

        <P>A 401k retirement savings plan.</P>

        <P>We offer a flexible four week parental leave for new parents.</P>

        <H1>Meet The Guild Team</H1>

        <P>We know when we are looking for a new job, we get a little anxious about guessing who we might end up working with.  Will we like them?  Will we mesh?  Here's some insight into The Guild team members in hopes it sheds some light on these questions.  Maybe we won't mesh, that's okay, thanks for stopping by! But if these three characters seem like your cup of tea, we'd love to meet you!</P>

        <H2>Zach</H2>
        <BioImage src={"bios/zach.png"} title="Engineer" tenure="6.5 years" linkedInUrl="https://www.linkedin.com/in/zachary-toben-bbab4a84/" />
        <P size="text-lg">If Zach was a plant, he would be Aloe Vera. Has the ability to grow and flourish in all environments, brings a calming and refreshing energy to those around him – often offering them care and relief.</P>
        <P size="text-lg">He most loves building software that people enjoy using, and takes pride in working on a team that is highly aligned on making that a reality, while fostering an environment that is a lot of fun to work in.</P>
        <P size="text-lg">Hobbies Zach is proud to share: Enjoying any and all sports, golfing (is that a sport?), tinkering with all things computer related, 3d printing, woodworking, and keeping all of my children alive.</P>
        <QuoteImages name="Zach" images={["bios/zach-quote-1.png", "bios/zach-quote-2.png", "bios/zach-quote-3.png"]} />

        <H2>Nick</H2>
        <BioImage src={"bios/nick-lick.jpeg"} title="Engineering Manager" tenure="3 years" linkedInUrl="https://www.linkedin.com/in/nicholastuck/" />
        <P size="text-lg">If Nick was a Piece of Furniture he would be a peculiar glass coffee table shaped like a human foot. Useful for so many activities, transparent, but doesn't quite conform to the traditional design of other tables.</P>
        <P size="text-lg">What he loves about his work the most is solving user's problems. What he appreciates the most about his job is the team's constant exploration into iterative solutions.</P>
        <P size="text-lg">Nick finds joy in changing hobbies every 6 months. Some of his most recent include: Bowling, Fitness, Reading (fiction & non-fiction) and embarrassing his kids with bad jokes when the right opportunity arises.</P>
        <QuoteImages name="Nick" images={["bios/nick-quote-1.png", "bios/nick-quote-2.png", "bios/nick-quote-3.png"]} />


        <H2>Christine</H2>
        <BioImage src={"bios/christine.jpeg"} title="Product Manager" tenure="1.5 years" linkedInUrl="https://www.linkedin.com/in/maurerchristine/" />
        <P size="text-lg">If Christine were an animal, she would be a border collie. Hardworking, loyal to the team, reliable, strategic and energetic. Also not afraid to herd the flock when needed.</P>
        <P size="text-lg">She most loves creating delightful experience that solve real customer problems. What she loves most about her job is working with incredibly smart and fun team members who make coming to work feel like something she gets to do instead of have to do. </P>
        <P size="text-lg">Christine enjoys reading, beach combing for shark teeth, fitness & nutrition, cycling, crochet, painting, traveling and spending time with her two dogs. </P>
        <QuoteImages name="Christine" images={["bios/christine-quote-1.png", "bios/christine-quote-2.png", "bios/christine-quote-3.png"]} />


        <H1>How We Hire</H1>
        <P>Switching jobs is a huge change, so we've tried to make our hiring practices transparent with minimal stress. Our technical interview is based on a short 1 to 2 hour take home exercise and follow up conversation with the team. If you are interested in an opportunity to join our team and help us build this product, you can expect:</P>

        <ol className="text-xl list-decimal ml-8">
          <li className="mt-4">A 30 minute call with Nick, the Engineering Manager on The Guild. He'll share a bit more about the company, listen to what you are looking for, and answer any questions you might have.</li>

          <li className="mt-4">If that goes well, we'll send you instructions for our technical interview. You can <A href="https://docs.google.com/document/d/1nFp5qJQxAOrwCKHQNeJTJs9jdYvuCV_yLEPTyCPQpDQ/edit#" target="_blank">read those now</A> if you'd like. This is a pretty fun exercise (see <A href="https://game-of-life-service-ai3nmiz7aa-uc.a.run.app/viewer/i45mZptT-JOU-fFQyxjsAuwal4vXTw">sample submission</A>) that mirrors some of the practical work we do. Once you are ready (usually within a week or two) we'll schedule the 2 hour technical interview to talk through your code. You'll never need to send us your code - it's all done through screen sharing. We'll have a couple of our engineers and the Guild's Product Manager join this call and ask you questions about your solution.</li>

          <li className="mt-4">Our final step will be a second interview, including Brian, our VP of Engineering, and some others to get to know more about you, your past experience, and the environments you have succeeded in the most. This is less about your technical skills and more about you the person. We'll also carve out some time here for you to ask our VP some of the hard questions.</li>
        </ol>

        <P>💡Some candidates prefer more time to ask questions and make sure this is the right job for them. In which case I'd love to talk more!</P>


        <P size="text-sm text-gray-400 dark:text-blue-400 mt-6">We are only hiring people based in the US, interested in full time employment. We are serious about building a team, so please don't inquire about contracting or C2C style work.</P>

      </Section>


      <section className="mt-6 pt-8 pb-4 bg-blue-900 text-white moving-gradients">
        <div className="text-center">
          <P className="text-2xl">Now, you know it all!<Strong> ...Are you interested?</Strong></P>
        </div>
        <div className="mt-8">
          <button
            className="block my-12 mx-auto font-hand text-white text-3xl lg:text-6xl shadow-xl uppercase rounded-lg px-8 py-4 border-4 border-white hover:bg-indigo-500"
            onClick={() => {
              measureEvent('contact form opened');
              setShowContact(true)
            }}
          >
            🌟Heck Yes!🌟
          </button>

          <ResponseButton label="not right now, but maybe later? 🕑" />
          <ResponseButton label="tbh, this job sounds kinda boring 😴" />
          <ResponseButton label="you aren't paying enough 💵" />
          <ResponseButton label="bright gradients burn my eyeballs 😎" />

          <P className="text-center text-sm lg:text-xl mt-12 mx-4">for additional information or inquries please email me, {hiringManagerName}, at <A href={`mailto:${hiringManagerEmail}`}>{hiringManagerEmail}</A>.</P>
        </div>
      </section>
      {showContact && <ContactModal onHide={() => setShowContact(false)}/>}

    </div>
  );
}

export default App;
